
import AdminService from '@/services/admin-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
@Component({
  components: { DefaultLayout }
})
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  @Watch('expanded')
  onExpandedChange(val: any, oldVal: any) {
    if (val.length > oldVal.length) {
      this.loadAdditionalLogEntries(val[val.length - 1].CustomerName);
    }
    if (val.length < oldVal.length) {
      this.removeExpandedEntires();
    }
  }
  @Watch('isAdminFiltered')
  onIsAdminFilteredChange() {
    this.loadLogEntries();
  }
  /* Data */
  isAdminFiltered: boolean = true;
  expanded: any = [];
  logs: any[] = [];
  expandedLogs: any[] = [];
  header: any[] = [
    {
      text: 'Customer',
      align: 'start',
      sortable: true,
      value: 'CustomerName'
    },
    {
      text: 'User',
      align: 'start',
      sortable: true,
      value: 'UserName'
    },
    {
      text: 'Description',
      align: 'start',
      sortable: true,
      value: 'Description'
    },
    {
      text: 'AccessTime',
      align: 'start',
      sortable: true,
      value: 'ActivityDateTime'
    }
  ];
  /* Utility Functions */
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  }
  removeExpandedEntires() {
    let newLogs = [];
    for (let log of this.expanded) {
      newLogs.push(this.expandedLogs.filter(l => l.CustomerName == log.CustomerName));
    }
    this.expandedLogs = newLogs;
  }
  filterLogByCustomer(customerName: string) {
    return this.expandedLogs.filter(l => l.CustomerName === customerName);
  }
  /* Loaders */
  async loadLogEntries() {
    const response = await AdminService.getActivitiesByDate('', !this.isAdminFiltered);
    if (response) {
      this.logs = response.data;
    }
  }
  async loadAdditionalLogEntries(customerName: string) {
    const { data: logs } = await AdminService.getActivityStreamByCustomerName(
      customerName,
      !this.isAdminFiltered
    );
    this.expandedLogs.push(...logs);
  }
  /* Computed */
  /* Mounted */
  mounted() {
    this.loadLogEntries();
  }
  /* Created */
}
